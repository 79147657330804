import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@happy-rabbit/component-library'

import {
  useCartStateContext,
  variantLineItemFromCart,
} from '../../contexts/CartContext'
import { useDrawerStateContext } from '../../contexts/DrawerContext'
import { useCountryStateContext } from '../../contexts/CountryContext'
import { useShopify } from '../../utils/shopify-api/shopifyContext'
import {
  deleteCart,
  proceedToCheckout,
} from '../../utils/shopify-api/shopifyApi'

const ShoppingCartButton = (props) => {
  const { externalReference, product, selectedVariant } = props
  const { handleAddToCart, cart: newCart, handleGoToCheckout } = useShopify()

  const { addVariantToCart, cart, updateLineItemInCart } = useCartStateContext()
  const { currencyCode } = useCountryStateContext()
  const { openRight } = useDrawerStateContext()
  const inventoryStatus =
    selectedVariant?.available === undefined || selectedVariant?.available
      ? 'in-stock'
      : 'out-of-stock'

  const inLineItem = useMemo(() => {
    if (externalReference?.variant_id) {
      const lineItem = variantLineItemFromCart(
        cart,
        externalReference.variant_id
      )
      return lineItem || null
    }
    return null
  }, [cart, externalReference])

  const inCartQty = inLineItem ? inLineItem.quantity : 0

  const handleAddToCartClick = useCallback(() => {
    const evtName = 'add'
    window.dataLayer = window.dataLayer || []

    try {
      window.dataLayer.push(function () {
        this.reset()
      })
      window.dataLayer.push({
        event: `eec.${evtName}`,
        ecommerce: {
          currencyCode,
          [evtName]: {
            actionField: {
              list: 'Product View',
            },
            products: [
              {
                id: selectedVariant.sku,
                name: `${[product.name, product.description?.en]
                  .filter(Boolean)
                  .join(' ')} / ${[
                  selectedVariant.productColorway?.name?.en,
                  selectedVariant.productSize?.abbreviation,
                ]
                  .filter(Boolean)
                  .join(' / ')}`,
                quantity: 1,
                dimension2: selectedVariant.productColorway?.name?.en || '',
                dimension3: selectedVariant.productSize?.abbreviation || '',
              },
            ],
          },
        },
      })
      handleAddToCart(externalReference.variant_id)
      openRight()
    } catch (e) {
      console.log(e)
    }
  }, [
    selectedVariant,
    product,
    externalReference,
    handleAddToCart,
    openRight,
    currencyCode,
  ])

  return (
    <>
      <Button
        fullWidth={true}
        disabled={
          !externalReference ||
          inventoryStatus === 'out-of-stock' ||
          cart?.cartRequest
        }
        onClick={handleAddToCartClick}
        theme="black"
      >
        <FormattedMessage
          id={
            inCartQty === 0 ? 'productView.add-to-cart' : 'productView.in-cart'
          }
          defaultMessage={
            inCartQty === 0 ? 'Add to cart' : '({quantity}) Add +1'
          }
          values={{
            quantity: inCartQty,
          }}
        />
      </Button>
    </>
  )
}

export default ShoppingCartButton
