import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../parts/Layout'
import ProductView from '../parts/ProductView'
import { useMetadata } from '../queries/metadata'
import { CMSProvider, ThemesProvider } from '@happy-rabbit/gatsby-sanity-cms'

const ProductPageTemplate = (props) => {
  const { data, pageContext, path } = props

  const productPageVariant = data.allSanityProductPageVariant.edges[0].node
  const productPage = productPageVariant.mainPage
  const pageConfig = productPageVariant.pageConfig
  const siteMetadata = useMetadata()

  const site = data.allSanitySite.edges[0]?.node
  const { lang } = pageContext

  const { themeReferences: themes } = site || {}

  return (
    <CMSProvider
      context={{
        ...pageContext,
        pageConfig,
        page: productPageVariant,
        path,
        site,
        siteMetadata,
      }}
    >
      <ThemesProvider themes={themes}>
        <Layout lang={lang}>
          <ProductView
            productPage={productPage}
            productPageVariant={productPageVariant}
          />
        </Layout>
      </ThemesProvider>
    </CMSProvider>
  )
}

export const pageQuery = graphql`
  query($siteId: String!, $productPageVariantId: String) {
    allSanitySite(filter: { _id: { eq: $siteId } }) {
      edges {
        node {
          _id

          defaultCurrencyCode
          slug {
            current
          }
          themeReferences {
            ...SanityThemeFragment
          }
        }
      }
    }

    allSanityProductPageVariant(
      filter: { _id: { eq: $productPageVariantId } }
    ) {
      edges {
        node {
          ...SanityProductPageVariantFragment
        }
      }
    }
  }
`

export default ProductPageTemplate
